<template>
  <span>
    <a href="javascript:void(0)" @click="buttonClicked()" class="text-danger"><a-icon type="delete"></a-icon></a>
  </span>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      displayValue: '',
    }
  },
  beforeMount() {
    this.displayValue = this.params.value
  },
  methods: {
    buttonClicked() {
      // console.log('this.params', this.params.data)
      // console.log('this.$parent', this.$parent.rowData)
      var indexTarget = this.$parent.rowData.findIndex(
        (x) => x.id === this.params.data.id,
      )
      this.$parent.rowData.splice(indexTarget, 1)
    },
  },
})
</script>

<style>

</style>
